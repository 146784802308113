<template>
  <el-card class="card pers-info" :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
    <el-row class="pers-info__header">
      <el-col class="pers-info__header__content">
        <div class="pers-info__header__content__title">
          <span class="pers-info__header__content__title__main">Personal Information</span>
          <span class="pers-info__header__content__title__sub">Update your personal information</span>
        </div>
        <div class="pers-info__header__content__toolbar">
          <gf-button type="success" @click="handleSave">Save Changes</gf-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="pers-info__body">
      <el-col class="pers-info__body__content">
        <el-form label-width="250px" label-position="left" class="pers-info__body__content__form" :model="user" ref="user" :rules="userRules">
          <el-form-item class="el-form-item--header">
            <span class="form-header">Personal Info</span>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Avatar">
            <div class="image-input">
              <img v-if="user.avatar_url || user.avatar_url !== ''" :src="user.avatar_url" alt="">
              <img v-else src="@/assets/avatar.jpg" alt="">
              <span class="tool tool__change" @click="handleAvatarChange">
                <i class="el-icon-edit"></i>
                <input type="file" @change="handleFileChange" ref="avatarInputFile" accept=".png, .jpg, .jpeg">
              </span>
              <span class="tool tool__remove" @click="handleClearAvatar"><i class="el-icon-close"></i></span>
            </div>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="First Name" prop="fname">
            <el-input class="el-input--dark" v-model="user.fname"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Middle Name" prop="mname">
            <el-input class="el-input--dark" v-model="user.mname"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Last Name" prop="lname">
            <el-input class="el-input--dark" v-model="user.lname"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Address" prop="address">
            <el-input class="el-input--dark" v-model="user.address"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--header">
            <span class="form-header">Contact Info</span>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Contact No" prop="phone_number">
            <el-input prefix-icon="el-icon-phone" class="el-input--dark" v-model="user.phone_number"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Email" prop="email">
            <el-input prefix-icon="el-icon-message" class="el-input--dark" v-model="user.email"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.card {
  flex-direction: column;
  height: 100%;
}

.image-input {
  position: relative;
  display: inline-block;
  border-radius: 5.46px;

  img {
    border: 3px solid #fff;
    width: 120px;
    height: 120px;
    box-shadow: 0px 6px 10px 6px rgba(0, 0, 0, 0.075);
    border-radius: 5.46px;
  }

  .tool {
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #ffffff;
    border-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #B5B5C3 !important;
      font-size: 12px;
    }

    &__change {
      position: absolute;
      top: -7px;
      right: -8px;

      input {
        width: 0 !important;
        height: 0 !important;
      }
    }

    &__remove {
      position: absolute;
      bottom: 7px;
      right: -8px;
    }
  }
}

.pers-info {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    min-height: 70px;
    border-bottom: 1px solid #EBEDF3;

    &__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9.75px 29.25px;

      &__title {
        display: flex;
        flex-direction: column;
        margin: 6.5px 6.5px 6.5px 0;

        &__main {
          color: #181C32;
          font-weight: 500;
          font-size: 16.575px;
        }

        &__sub {
          color: #b5b5c3;
          font-weight: 500;
          font-size: 12.025px;
          margin-top: 3.25px;
        }
      }
    }
  }

  &__body {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__content {
      padding: 9.75px 29.25px;
      flex-grow: 1;
      height: 0;
      overflow: auto;
    }
  }
}
</style>

<script>
import UserProfileService from '@/services/v1/UserProfile'

export default {
  props: {
    active: Number
  },
  data () {
    return {
      user: {
        fname: null,
        mname: null,
        lname: null,
        address: null,
        phone_number: null,
        email: null,
        avatar_url: null
      },

      // rules
      userRules: {
        fname: [
          { required: true, message: 'First Name can\'t be empty', trigger: 'blur' }
        ],
        mname: [
          { required: true, message: 'Middle Name can\'t be empty', trigger: 'blur' }
        ],
        lname: [
          { required: true, message: 'Last Name can\'t be empty', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Address can\'t be empty', trigger: 'blur' }
        ],
        phone_number: [
          { required: true, message: 'Contact Number can\'t be empty', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Email can\'t be empty', trigger: 'blur' },
          { type: 'email', message: 'Invalid email value', trigger: 'blur' }
        ]
      },

      // services
      upService: null
    }
  },
  methods: {
    async handleSave () {
      this.$refs.user.validate()
        .then(async () => {
          try {
            const upService = new UserProfileService()
            await upService.updatePersonalInfo(this.user)
            this.$message.success('Changes successfully save')
            this.$emit('update')

            const usr = JSON.parse(localStorage.getItem('user'))
            usr.avatar_url = this.user.avatar_url
            localStorage.setItem('user', JSON.stringify(usr))
          } catch (error) {
            this.$Error(error)
          }
        })
        .catch(() => {})
    },
    handleAvatarChange () {
      this.$refs.avatarInputFile.click()
    },
    handleClearAvatar () {
      this.user.avatar_url = ''
    },
    async handleFileChange (e) {
      try {
        const img = e.target.files[0]
        const fd = new FormData()
        fd.append('image', img)

        const upService = new UserProfileService()
        const result = await upService.uploadAvatar(fd)
        this.user.avatar_url = result.data.path
      } catch (error) {
        this.$Error(error)
      } finally {
        this.$refs.avatarInputFile.value = ''
      }
    },

    // fetch
    async getProfile () {
      try {
        const upService = new UserProfileService()
        const result = await upService.get()
        this.user = result.data
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  mounted () {
    this.$emit('update:active', 1)
    this.getProfile()
  }
}
</script>
